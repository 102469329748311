.schedule-section {
  background-color: $c-white;

  .title-container {
    padding: 30px 30px; }

  .mobile-tabs {
    display: flex;
    justify-content: center;

    @include bp(max-width, $break-large) {
      flex-wrap: wrap; }

    .tab {
      display: flex;
      flex-direction: column;
      width: percentage(1 / 6);

      @include bp(max-width, $break-large) {
        width: percentage(1 / 3); }

      @include bp(max-width, $break-small) {
        width: 100%; } }

    .tab-content,
    .tab-trigger {
      text-align: center;

      p {
        text-transform: capitalize; } }

    .tab-trigger {
      background-color: $c-black;
      color: $c-white;
      border: 1px solid $c-white;

      p {
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 10px; } }

    .tab-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      p {
        margin-top: 10px;

        + p {
          margin-bottom: 10px; } }

      .training-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        border: 1px solid $c-white;

        &[data-training='kalistenika Z'] {
          flex: 2;
          padding-top: 1px;
          padding-bottom: 1px; } } } }

  [data-training*='reborn'] {
    background-color: $c-red;
    color: $c-white; }

  [data-training*='kalistenika'] {
    background-color: $c-yellow;
    color: $c-black; }

  [data-training*='obwodowy'] {
    background-color: $c-green;
    color: $c-white; }

  [data-training*='rozciąganie'] {
    background-color: $c-blue;
    color: $c-white; }

  [data-training*='wolna sala'] {
    background-color: #797979;
    color: $c-white; } }
