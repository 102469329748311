.contact-wrapper {
  background-color: $c-white;

  .contact-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p + p {
      padding-top: 10px; }

    .media-links i {
      font-size: 24px;
      color: $c-black;

      &:hover {
        color: $c-yellow; } } } }


#map {
  width: 100%;
  height: 600px;
  box-shadow: 1px 7px 10px -9px rgba(0,0,0,0.75); }
