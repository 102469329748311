* {
  box-sizing: border-box; }

body {
  font-family: $f-arimo;
  color: $c-black;
  -webkit-font-smoothing: antialiased; }

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 30px;

  @include bp(max-width, $break-large) {
    padding: 0 20px; } }

// PARTICLES JS

#particles-js {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%; }

a {
  text-decoration: none;
  color: $c-link;
  transition: .2s;

  &:hover {
    color: $c-link-hover; } }

p {
  font-family: $f-pt-sans-narrow;
  font-size: $f-size-xlarge;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1.3px; }

i {
  &:hover {
    cursor: pointer; } }

h1 {
  font-family: 'Allerta Stencil';
  letter-spacing: 5px;
  font-weight: bold;
  text-transform: uppercase; }

h3 {
  font-family: $f-pt-sans-narrow;
  font-weight: bold;
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
  color: $c-black; }

.section-title {
  padding-bottom: 30px; }

.section-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #f2f2f2; }

img {
  max-width: 100%; }

// CENTER

.center {
  justify-content: center;
  align-items: center; }

.hidden {
  visibility: hidden; }

.visible {
  visibility: visible; }

.opacity-on {
  opacity: 1; }

.opacity-8 {
  opacity: 0.8; }

.opacity-off {
  opacity: 0; }

.clock-animation {
  animation-name: tada;
  animation-duration: 200ms; }

.ring-menu-on {
  transform: translateY(100%); }

.none {
  @media (max-width: $break-xsmall) {
    display: none; } }

.height-70 {
  height: 70px; }

@media (max-width: $break-small) {
  .mobile-tabs .tab:not(.on) .tab-content {
    display: none; } }

/* ============================================ *
 * Color classes
 * ============================================ */

.color-yellow {
  color: $c-yellow; }

.color-white {
  color: $c-white; }

.color-black {
  color: $c-black; }
