.glitch {
  position: relative;

  &:before {
    text-shadow: 0.7px 0 #ffdc36;
    animation: effect 2s infinite;
    transform: rotate(2deg); }

  &:after {
    text-shadow: -0.1px 0 #ff0005;
    animation: effect 1.4s infinite;
    transform: rotate(-1deg); }

  &:before,
  &:after {
    @extend .flex-center;

    content: 'grafik';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    &:hover {
      animation-play-state: paused; } } }

@keyframes effect {
  0% {
    clip: rect(55px, 9999px, 56px, 0); }
  5% {
    clip: rect(47px, 9999px, 30px, 0); }
  10% {
    clip: rect(33px, 9999px, 36px, 0); }
  15% {
    clip: rect(35px, 9999px, 9px, 0); }
  20% {
    clip: rect(63px, 9999px, 59px, 0); }
  25% {
    clip: rect(96px, 9999px, 98px, 0); }
  30% {
    clip: rect(48px, 9999px, 77px, 0); }
  35% {
    clip: rect(50px, 9999px, 34px, 0); }
  40% {
    clip: rect(60px, 9999px, 72px, 0); }
  45% {
    clip: rect(55px, 9999px, 56px, 0); }
  50% {
    clip: rect(2px, 9999px, 60px, 0); }
  55% {
    clip: rect(23px, 9999px, 89px, 0); }
  60% {
    clip: rect(76px, 9999px, 91px, 0); }
  65% {
    clip: rect(62px, 9999px, 30px, 0); }
  70% {
    clip: rect(60px, 9999px, 73px, 0); }
  75% {
    clip: rect(60px, 9999px, 87px, 0); }
  80% {
    clip: rect(2px, 9999px, 14px, 0); }
  85% {
    clip: rect(28px, 9999px, 87px, 0); }
  90% {
    clip: rect(73px, 9999px, 50px, 0); }
  95% {
    clip: rect(79px, 9999px, 70px, 0); }
  100% {
    clip: rect(17px, 9999px, 79px, 0); } }
