// =============================================
// Mixin - Breakpoint
// =============================================

@mixin bp($feature, $value) {
  $media: only screen;

  @media #{$media} and ($feature: $value) {
    @content; } }

@mixin cool-transition {
  transition: all 1s cubic-bezier(.23,1,.32,1);
  transition-delay: .2s; }




