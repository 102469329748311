footer {
  background-color: $c-black;

  &.section-wrapper {
    border-top: 0; }

  .copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include bp(max-width, $break-small) {
      justify-content: center;
      flex-direction: column; }

    .copy-icon {
      margin-right: 5px; }

    a {
      display: block;
      font-size: 15px;
      color: $c-white;

      &.home {
        position: relative;

        &:before {
          position: absolute;
          top: -2px;
          left: -7px;
          content: '';
          display: block;
          height: 20px;
          width: 1px;
          background-color: $c-white;
          opacity: .4; } }

      &:hover {
        color: $c-yellow; }

      &.facebook {
        font-size: 20px; }

      + a {
        margin-left: 15px; } }

    .footer-links {
      display: flex; }

    .media-links {
      font-size: $f-size-xxlarge;

      @include bp(max-width, $break-small) {
        padding-top: 20px; } } } }
