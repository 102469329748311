.flex {
  display: flex; }

.flex-row {
  flex-direction: row; }

.flex-column {
  flex-direction: column; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }
