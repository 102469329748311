.trainers-wrapper {
  background-color: $c-white;

  .pictures-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }

  .picture-container {
    max-width: 320px;
    position: relative;
    padding: 10px;

    &:hover {
      img {
        opacity: 0; }

      .paragraph-wrapper {
        opacity: 1; } }

    img {
      z-index: 1;
      transition: .2s; }


    .paragraph-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 30px;
      text-align: center;
      opacity: 0;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        letter-spacing: 1.4px;

        a {
          font-weight: bold; } } } } }
