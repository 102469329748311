.pricing-wrapper {
  background-color: $c-black;
  padding-bottom: 30px;

  .pricing-header {
    .pricing-notice {
      display: block;
      color: $c-white;
      margin-bottom: 10px;
      font-weight: bold; } }

  .pricing-details {
    display: flex;

    @include bp(max-width, $break-large) {
      flex-wrap: wrap; } }

  h3,
  .pricing {
    color: $c-white; }

  .pricing {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid $c-border;
    border-top: 0;

    + .pricing {
      border-left: 0;

      @include bp(max-width, $break-small) {
        border-left: 1px solid $c-border;

        h3 {
          border-top: 0; } } }

    @include bp(max-width, $break-large) {
      width: 50%;

      &:nth-child(4n) h3 {
        border-top: 0; }

      &:nth-child(4n-1) {
        border-left: 1px solid $c-border;

        h3 {
          border-top: 0; } } }

    @include bp(max-width, $break-small) {
      width: 100%; }

    h3 {
      border-top: 1px solid $c-border;
      border-bottom: 1px solid $c-border;
      padding: 10px;
      font-size: 25px;
      background-color: lighten($c-black, 7%); }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;

      &.first:not(:last-of-type) {
        border-bottom: 1px solid $c-border; }

      &:not(.first) {
        height: 100%; } } }

  .price-wrapper {
    flex: 1;

    .price-value {
      color: $c-yellow;
      font-weight: bold; } } }
