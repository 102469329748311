@mixin swingingAnimation() {
  transform-origin: 50% 0;
  animation: swinging 3.5s ease-in-out forwards infinite;

  @keyframes swinging {
    0% {
      transform: rotate(10deg); }

    50% {
      transform: rotate(-5deg); }

    100% {
      transform: rotate(10deg); } } }
