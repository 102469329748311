.headroom--unpinned {
  transform: translateY(-100%); }

.headroom--pinned {
  transform: translateY(0); }

.headroom--top {
  @include bp(max-width, $break-small) {
    transform: translateY(-100%); } }

.headroom {
  transition: transform .2s ease-in-out; }

.headroom--not-top {
  ul {
    a {
      color: $c-black;

      &:hover {
        transition: 200ms; } } } }
