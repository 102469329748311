.hero-banner-wrapper {
  @extend .flex-center;

  text-align: center;
  position: relative;
  z-index: 0;

  @include bp(max-width, $break-small) {
    background-image: url(../img/mobile-background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    opacity: .8; }

  .mobile-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $c-overlay;

    @include bp(min-width, $break-small + 1px) {
      display: none; } }

  .heading-container {
    max-width: 100%;
    width: 100%;
    padding: 0 30px;
    z-index: 1;
    transition: all 1s cubic-bezier(.23,1,.32,1);

    .heading-title {
      font-size: 80px;

      @include cool-transition();
      @include bp(max-width, $break-large) {
        font-size: 50px;
        line-height: 55px; }

      @include bp(max-width, $break-small) {
        font-size: 40px;
        line-height: 45px; } } } }
