.about-us-wrapper {
  background-color: $c-black;

  .section-content {
    height: 150px; }

  .paragraph-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    color: $c-white;

    p {
      text-align: center;
      transition: .5s;
      color: $c-white;
      opacity: 0;
      position: absolute;
      top: auto;

      &.show {
        opacity: .9; } } } }

.heart {
  fill: $c-yellow;
  position: relative;
  top: 10px;
  width: 20px;
  animation: pulse 1s ease infinite; }


@keyframes pulse {
  0% {
    transform: scale(1); }
  10% {
    transform: scale(1.3); }
  20% {
    transform: scale(1); }
  30% {
    transform: scale(1.3); }
  100% {
    transform: scale(1); } }

