.video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-color: $c-overlay; }

video {
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: -2;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);

  @media (max-width: $break-small) {
    display: none; } }

.section-video-divider {
  position: relative;
  overflow: hidden;
  height: 550px;
  @media (max-width: $break-xsmall) {
    height: 60vw;
    overflow: hidden; }
  img {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    opacity: .7;
    @media (max-width: $break-xsmall) {
      display: block; } }
  h1 {
    opacity: .5;
    text-align: center;
    font-size: 6rem;
    font-style: normal;
    color: darken($c-white, 70);
    flex-direction: column;
    align-items: center;
    transition: all 1s cubic-bezier(.23,1,.32,1);
    transition-delay: .5s;
    white-space: nowrap;
    animation-duration: .4s;
    @include cool-transition;
    .second-line-heading {
      font-size: 1.3em; }
    @media (max-width: $break-medium) {
      font-size: 4rem; }
    @media (max-width: $break-small) {
      font-size: 3rem; }
    @media (max-width: $break-xsmall) {
      font-size: 1.3rem;
      color: darken($c-white, 0);
      opacity: .9; } } }



