// =============================================
// Header
// =============================================

header {
  background: $c-white;
  box-shadow: 0 0 1px 0 $c-black;
  z-index: 10;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;

  @include bp(max-width, $break-medium) {
    display: none; } }

.header-container {
  @extend .flex;

  justify-content: space-between;

  .menu-trigger-wrapper {
    position: fixed;
    transition: .4s;
    top: -10px;

    @include bp(min-width, $break-medium + 1px) {
      display: none; }

    @include swingingAnimation();

    &.active {
      opacity: .5; } }

  .page-logo-container {
    max-width: 130px;
    padding: $padding-10 * 1.2;

    .page-logo-link {
      &:hover {
        @extend .flash;

        display: block;
        animation-duration: .3s; } }

    .page-logo {
      display: block; }

    @include bp(max-width, $break-medium) {
      display: none; } }

  .navigation-wrapper {
    @extend .flex;

    @include bp(max-width, $break-medium) {
      display: none; }

    .navigation-container {
      @extend .flex;

      transition: .2s;

      li {
        @extend .flex-center;

        + li {
          margin-left: 15px; }

        a {
          text-transform: uppercase;
          font-size: $f-size-normal;
          font-weight: $f-weight-bold;

          &:hover {
            span {
              @extend .flash;

              animation-duration: .3s; } } } } } } }

// =============================================
// Mobile navigation
// =============================================

.navigation-wrapper.mobile-nav {
  display: none;
  pointer-events: none;

  @include bp(max-width, $break-medium) {
    position: fixed;
    display: flex;
    width: 100%;
    z-index: 2;

    .navigation-container {
      box-shadow: 1px 1px 10px 0 rgba(0,0,0,0.2); }

    &.active {
      pointer-events: all;

      .navigation-container {
        transform: translateX(0); } }

    .navigation-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: $c-white;
      transform: translateY(-110%);
      transition: transform .3s ease-in-out;

      li {
        padding: 10px 20px;
        border-top: 1px solid #f5f5f5;

        &:last-of-type {
          border-bottom: 1px solid #f5f5f5; } } }

    a {
      text-transform: uppercase;
      width: 100%;
      height: 100%;
      font-size: 13px;

      &.logotype {
        text-transform: capitalize;

        .color-yellow {
          color: $c-yellow; }

        > span span {
          display: inline;
          font-weight: bold;
          font-family: $f-pt-sans-narrow;
          font-size: 15px; } }

      > span {
        width: 100%;
        height: 100%;
        display: flex;
        font-weight: 600;

        &:not(.color-yellow) {
          color: $c-black; } } } } }

// =============================================
// Mobile navigation trigger
// =============================================

.menu-trigger-wrapper {
  position: absolute;
  top: 2px;
  right: 10px;
  pointer-events: all;
  z-index: 3;
  padding: 15px 10px;

  &:before,
  &:after {
    display: block;
    content: '';
    width: 20px;
    height: 2px;
    transition: all .4s cubic-bezier(.35,1,.35,1);
    transform-origin: 45%;
    background-color: $c-yellow; }

  &:after {
    transform: translateY(7px); } }

.navigation-wrapper.mobile-nav.active .menu-trigger-wrapper {
  &:before {
    background-color: $c-black;
    transform: rotate(45deg) translateZ(0); }

  &:after {
    transform: rotate(-45deg) translateZ(0); } }
