@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* ============================================ *
 * Font family
 * ============================================ */
/* ============================================ *
 * Font size
 * ============================================ */
/* ============================================ *
 * Font weight
 * ============================================ */
/* ============================================ *
 * Colors
 * ============================================ */
/* ============================================ *
 * Spacings
 * ============================================ */
/* ============================================ *
 * Breakpoints
 * ============================================ */
.flex, .header-container, .header-container .navigation-wrapper, .header-container .navigation-wrapper .navigation-container {
  display: flex; }

.flex-row {
  flex-direction: row; }

.flex-column {
  flex-direction: column; }

.flex-center, .header-container .navigation-wrapper .navigation-container li, .hero-banner-wrapper, .glitch:before, .glitch:after {
  display: flex;
  justify-content: center;
  align-items: center; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.animated.infinite {
  animation-iteration-count: infinite; }

.animated.hinge {
  animation-duration: 2s; }

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  animation-duration: .75s; }

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0); }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0); }
  90% {
    transform: translate3d(0, -4px, 0); } }

.bounce {
  animation-name: bounce;
  transform-origin: center bottom; }

@keyframes flash {
  from, 50%, to {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

.flash, .header-container .page-logo-container .page-logo-link:hover, .header-container .navigation-wrapper .navigation-container li a:hover span {
  animation-name: flash; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1); }
  50% {
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-name: pulse; }

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1); }
  30% {
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    transform: scale3d(1.05, 0.95, 1); }
  to {
    transform: scale3d(1, 1, 1); } }

.rubberBand {
  animation-name: rubberBand; }

@keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0); } }

.shake {
  animation-name: shake; }

@keyframes headShake {
  0% {
    transform: translateX(0); }
  6.5% {
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    transform: translateX(2px) rotateY(3deg); }
  50% {
    transform: translateX(0); } }

.headShake {
  animation-timing-function: ease-in-out;
  animation-name: headShake; }

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  transform-origin: top center;
  animation-name: swing; }

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    transform: scale3d(1, 1, 1); } }

.tada {
  animation-name: tada; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes wobble {
  from {
    transform: none; }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    transform: none; } }

.wobble {
  animation-name: wobble; }

@keyframes jello {
  from, 11.1%, to {
    transform: none; }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

.jello {
  animation-name: jello;
  transform-origin: center; }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1); } }

.bounceIn {
  animation-name: bounceIn; }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    transform: none; } }

.bounceInDown {
  animation-name: bounceInDown; }

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounceInLeft {
  animation-name: bounceInLeft; }

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

.bounceInRight {
  animation-name: bounceInRight; }

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  animation-name: bounceInUp; }

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); } }

.bounceOut {
  animation-name: bounceOut; }

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.bounceOutDown {
  animation-name: bounceOutDown; }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

.bounceOutLeft {
  animation-name: bounceOutLeft; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.bounceOutRight {
  animation-name: bounceOutRight; }

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.bounceOutUp {
  animation-name: bounceOutUp; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInDown {
  animation-name: fadeInDown; }

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInDownBig {
  animation-name: fadeInDownBig; }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInLeft {
  animation-name: fadeInLeft; }

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInLeftBig {
  animation-name: fadeInLeftBig; }

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInRight {
  animation-name: fadeInRight; }

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInRightBig {
  animation-name: fadeInRightBig; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInUp {
  animation-name: fadeInUp; }

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInUpBig {
  animation-name: fadeInUpBig; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  animation-name: fadeOutDown; }

@keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.fadeOutDownBig {
  animation-name: fadeOutDownBig; }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); } }

.fadeOutLeft {
  animation-name: fadeOutLeft; }

@keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig; }

@keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0); } }

.fadeOutRight {
  animation-name: fadeOutRight; }

@keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.fadeOutRightBig {
  animation-name: fadeOutRightBig; }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  animation-name: fadeOutUp; }

@keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.fadeOutUpBig {
  animation-name: fadeOutUpBig; }

@keyframes flip {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out; }
  40% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out; }
  50% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in; }
  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in; }
  to {
    transform: perspective(400px);
    animation-timing-function: ease-in; } }

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  animation-name: flip; }

@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipInX; }

@keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    transform: perspective(400px); } }

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipInY; }

@keyframes flipOutX {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.flipOutX {
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@keyframes flipOutY {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipOutY; }

@keyframes lightSpeedIn {
  from {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    transform: skewX(-5deg);
    opacity: 1; }
  to {
    transform: none;
    opacity: 1; } }

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out; }

@keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in; }

@keyframes rotateIn {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    transform-origin: center;
    transform: none;
    opacity: 1; } }

.rotateIn {
  animation-name: rotateIn; }

@keyframes rotateInDownLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1; } }

.rotateInDownLeft {
  animation-name: rotateInDownLeft; }

@keyframes rotateInDownRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1; } }

.rotateInDownRight {
  animation-name: rotateInDownRight; }

@keyframes rotateInUpLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1; } }

.rotateInUpLeft {
  animation-name: rotateInUpLeft; }

@keyframes rotateInUpRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1; } }

.rotateInUpRight {
  animation-name: rotateInUpRight; }

@keyframes rotateOut {
  from {
    transform-origin: center;
    opacity: 1; }
  to {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.rotateOut {
  animation-name: rotateOut; }

@keyframes rotateOutDownLeft {
  from {
    transform-origin: left bottom;
    opacity: 1; }
  to {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft; }

@keyframes rotateOutDownRight {
  from {
    transform-origin: right bottom;
    opacity: 1; }
  to {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutDownRight {
  animation-name: rotateOutDownRight; }

@keyframes rotateOutUpLeft {
  from {
    transform-origin: left bottom;
    opacity: 1; }
  to {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft; }

@keyframes rotateOutUpRight {
  from {
    transform-origin: right bottom;
    opacity: 1; }
  to {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.rotateOutUpRight {
  animation-name: rotateOutUpRight; }

@keyframes hinge {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.hinge {
  animation-name: hinge; }

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom; }
  50% {
    transform: rotate(-10deg); }
  70% {
    transform: rotate(3deg); }
  to {
    opacity: 1;
    transform: scale(1); } }

.jackInTheBox {
  animation-name: jackInTheBox; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    transform: none; } }

.rollIn {
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.rollOut {
  animation-name: rollOut; }

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  animation-name: zoomIn; }

@keyframes zoomInDown {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  animation-name: zoomInDown; }

@keyframes zoomInLeft {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  animation-name: zoomInLeft; }

@keyframes zoomInRight {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInRight {
  animation-name: zoomInRight; }

@keyframes zoomInUp {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInUp {
  animation-name: zoomInUp; }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.zoomOut {
  animation-name: zoomOut; }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutDown {
  animation-name: zoomOutDown; }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform-origin: left center; } }

.zoomOutLeft {
  animation-name: zoomOutLeft; }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    transform: scale(0.1) translate3d(2000px, 0, 0);
    transform-origin: right center; } }

.zoomOutRight {
  animation-name: zoomOutRight; }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutUp {
  animation-name: zoomOutUp; }

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  animation-name: slideInDown; }

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  animation-name: slideInLeft; }

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInRight {
  animation-name: slideInRight; }

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  animation-name: slideInUp; }

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0); } }

.slideOutDown {
  animation-name: slideOutDown; }

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  animation-name: slideOutLeft; }

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0); } }

.slideOutRight {
  animation-name: slideOutRight; }

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0); } }

.slideOutUp {
  animation-name: slideOutUp; }

* {
  box-sizing: border-box; }

body {
  font-family: "Arimo", sans-serif;
  color: #333333;
  -webkit-font-smoothing: antialiased; }

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 30px; }
  @media only screen and (max-width: 979px) {
    .container {
      padding: 0 20px; } }

#particles-js {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%; }

a {
  text-decoration: none;
  color: #2b2b2b;
  transition: .2s; }
  a:hover {
    color: #848484; }

p {
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1.3px; }

i:hover {
  cursor: pointer; }

h1 {
  font-family: 'Allerta Stencil';
  letter-spacing: 5px;
  font-weight: bold;
  text-transform: uppercase; }

h3 {
  font-family: "PT Sans Narrow", sans-serif;
  font-weight: bold;
  font-size: 30px;
  text-transform: capitalize;
  text-align: center;
  color: #333333; }

.section-title {
  padding-bottom: 30px; }

.section-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #f2f2f2; }

img {
  max-width: 100%; }

.center {
  justify-content: center;
  align-items: center; }

.hidden {
  visibility: hidden; }

.visible {
  visibility: visible; }

.opacity-on {
  opacity: 1; }

.opacity-8 {
  opacity: 0.8; }

.opacity-off {
  opacity: 0; }

.clock-animation {
  animation-name: tada;
  animation-duration: 200ms; }

.ring-menu-on {
  transform: translateY(100%); }

@media (max-width: 479px) {
  .none {
    display: none; } }

.height-70 {
  height: 70px; }

@media (max-width: 599px) {
  .mobile-tabs .tab:not(.on) .tab-content {
    display: none; } }

/* ============================================ *
 * Color classes
 * ============================================ */
.color-yellow {
  color: #ffb400; }

.color-white {
  color: #ffffff; }

.color-black {
  color: #333333; }

.video-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-color: rgba(66, 161, 219, 0.11); }

video {
  pointer-events: none;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: -2;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%); }
  @media (max-width: 599px) {
    video {
      display: none; } }

.section-video-divider {
  position: relative;
  overflow: hidden;
  height: 550px; }
  @media (max-width: 479px) {
    .section-video-divider {
      height: 60vw;
      overflow: hidden; } }
  .section-video-divider img {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    opacity: .7; }
    @media (max-width: 479px) {
      .section-video-divider img {
        display: block; } }
  .section-video-divider h1 {
    opacity: .5;
    text-align: center;
    font-size: 6rem;
    font-style: normal;
    color: #4d4d4d;
    flex-direction: column;
    align-items: center;
    transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
    transition-delay: .5s;
    white-space: nowrap;
    animation-duration: .4s;
    transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
    transition-delay: .2s; }
    .section-video-divider h1 .second-line-heading {
      font-size: 1.3em; }
    @media (max-width: 770px) {
      .section-video-divider h1 {
        font-size: 4rem; } }
    @media (max-width: 599px) {
      .section-video-divider h1 {
        font-size: 3rem; } }
    @media (max-width: 479px) {
      .section-video-divider h1 {
        font-size: 1.3rem;
        color: white;
        opacity: .9; } }

.headroom--unpinned {
  transform: translateY(-100%); }

.headroom--pinned {
  transform: translateY(0); }

@media only screen and (max-width: 599px) {
  .headroom--top {
    transform: translateY(-100%); } }

.headroom {
  transition: transform .2s ease-in-out; }

.headroom--not-top ul a {
  color: #333333; }
  .headroom--not-top ul a:hover {
    transition: 200ms; }

header {
  background: #ffffff;
  box-shadow: 0 0 1px 0 #333333;
  z-index: 10;
  position: fixed;
  right: 0;
  left: 0;
  top: 0; }
  @media only screen and (max-width: 770px) {
    header {
      display: none; } }

.header-container {
  justify-content: space-between; }
  .header-container .menu-trigger-wrapper {
    position: fixed;
    transition: .4s;
    top: -10px;
    transform-origin: 50% 0;
    animation: swinging 3.5s ease-in-out forwards infinite; }
    @media only screen and (min-width: 771px) {
      .header-container .menu-trigger-wrapper {
        display: none; } }

@keyframes swinging {
  0% {
    transform: rotate(10deg); }
  50% {
    transform: rotate(-5deg); }
  100% {
    transform: rotate(10deg); } }
    .header-container .menu-trigger-wrapper.active {
      opacity: .5; }
  .header-container .page-logo-container {
    max-width: 130px;
    padding: 12px; }
    .header-container .page-logo-container .page-logo-link:hover {
      display: block;
      animation-duration: .3s; }
    .header-container .page-logo-container .page-logo {
      display: block; }
    @media only screen and (max-width: 770px) {
      .header-container .page-logo-container {
        display: none; } }
  @media only screen and (max-width: 770px) {
    .header-container .navigation-wrapper {
      display: none; } }
  .header-container .navigation-wrapper .navigation-container {
    transition: .2s; }
    .header-container .navigation-wrapper .navigation-container li + li {
      margin-left: 15px; }
    .header-container .navigation-wrapper .navigation-container li a {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700; }
      .header-container .navigation-wrapper .navigation-container li a:hover span {
        animation-duration: .3s; }

.navigation-wrapper.mobile-nav {
  display: none;
  pointer-events: none; }
  @media only screen and (max-width: 770px) {
    .navigation-wrapper.mobile-nav {
      position: fixed;
      display: flex;
      width: 100%;
      z-index: 2; }
      .navigation-wrapper.mobile-nav .navigation-container {
        box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.2); }
      .navigation-wrapper.mobile-nav.active {
        pointer-events: all; }
        .navigation-wrapper.mobile-nav.active .navigation-container {
          transform: translateX(0); }
      .navigation-wrapper.mobile-nav .navigation-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        transform: translateY(-110%);
        transition: transform .3s ease-in-out; }
        .navigation-wrapper.mobile-nav .navigation-container li {
          padding: 10px 20px;
          border-top: 1px solid #f5f5f5; }
          .navigation-wrapper.mobile-nav .navigation-container li:last-of-type {
            border-bottom: 1px solid #f5f5f5; }
      .navigation-wrapper.mobile-nav a {
        text-transform: uppercase;
        width: 100%;
        height: 100%;
        font-size: 13px; }
        .navigation-wrapper.mobile-nav a.logotype {
          text-transform: capitalize; }
          .navigation-wrapper.mobile-nav a.logotype .color-yellow {
            color: #ffb400; }
          .navigation-wrapper.mobile-nav a.logotype > span span {
            display: inline;
            font-weight: bold;
            font-family: "PT Sans Narrow", sans-serif;
            font-size: 15px; }
        .navigation-wrapper.mobile-nav a > span {
          width: 100%;
          height: 100%;
          display: flex;
          font-weight: 600; }
          .navigation-wrapper.mobile-nav a > span:not(.color-yellow) {
            color: #333333; } }

.menu-trigger-wrapper {
  position: absolute;
  top: 2px;
  right: 10px;
  pointer-events: all;
  z-index: 3;
  padding: 15px 10px; }
  .menu-trigger-wrapper:before, .menu-trigger-wrapper:after {
    display: block;
    content: '';
    width: 20px;
    height: 2px;
    transition: all 0.4s cubic-bezier(0.35, 1, 0.35, 1);
    transform-origin: 45%;
    background-color: #ffb400; }
  .menu-trigger-wrapper:after {
    transform: translateY(7px); }

.navigation-wrapper.mobile-nav.active .menu-trigger-wrapper:before {
  background-color: #333333;
  transform: rotate(45deg) translateZ(0); }

.navigation-wrapper.mobile-nav.active .menu-trigger-wrapper:after {
  transform: rotate(-45deg) translateZ(0); }

.hero-banner-wrapper {
  text-align: center;
  position: relative;
  z-index: 0; }
  @media only screen and (max-width: 599px) {
    .hero-banner-wrapper {
      background-image: url(../img/mobile-background.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
      opacity: .8; } }
  .hero-banner-wrapper .mobile-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(66, 161, 219, 0.11); }
    @media only screen and (min-width: 600px) {
      .hero-banner-wrapper .mobile-overlay {
        display: none; } }
  .hero-banner-wrapper .heading-container {
    max-width: 100%;
    width: 100%;
    padding: 0 30px;
    z-index: 1;
    transition: all 1s cubic-bezier(0.23, 1, 0.32, 1); }
    .hero-banner-wrapper .heading-container .heading-title {
      font-size: 80px;
      transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
      transition-delay: .2s; }
      @media only screen and (max-width: 979px) {
        .hero-banner-wrapper .heading-container .heading-title {
          font-size: 50px;
          line-height: 55px; } }
      @media only screen and (max-width: 599px) {
        .hero-banner-wrapper .heading-container .heading-title {
          font-size: 40px;
          line-height: 45px; } }

.glitch {
  position: relative; }
  .glitch:before {
    text-shadow: 0.7px 0 #ffdc36;
    animation: effect 2s infinite;
    transform: rotate(2deg); }
  .glitch:after {
    text-shadow: -0.1px 0 #ff0005;
    animation: effect 1.4s infinite;
    transform: rotate(-1deg); }
  .glitch:before, .glitch:after {
    content: 'grafik';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden; }
    .glitch:before:hover, .glitch:after:hover {
      animation-play-state: paused; }

@keyframes effect {
  0% {
    clip: rect(55px, 9999px, 56px, 0); }
  5% {
    clip: rect(47px, 9999px, 30px, 0); }
  10% {
    clip: rect(33px, 9999px, 36px, 0); }
  15% {
    clip: rect(35px, 9999px, 9px, 0); }
  20% {
    clip: rect(63px, 9999px, 59px, 0); }
  25% {
    clip: rect(96px, 9999px, 98px, 0); }
  30% {
    clip: rect(48px, 9999px, 77px, 0); }
  35% {
    clip: rect(50px, 9999px, 34px, 0); }
  40% {
    clip: rect(60px, 9999px, 72px, 0); }
  45% {
    clip: rect(55px, 9999px, 56px, 0); }
  50% {
    clip: rect(2px, 9999px, 60px, 0); }
  55% {
    clip: rect(23px, 9999px, 89px, 0); }
  60% {
    clip: rect(76px, 9999px, 91px, 0); }
  65% {
    clip: rect(62px, 9999px, 30px, 0); }
  70% {
    clip: rect(60px, 9999px, 73px, 0); }
  75% {
    clip: rect(60px, 9999px, 87px, 0); }
  80% {
    clip: rect(2px, 9999px, 14px, 0); }
  85% {
    clip: rect(28px, 9999px, 87px, 0); }
  90% {
    clip: rect(73px, 9999px, 50px, 0); }
  95% {
    clip: rect(79px, 9999px, 70px, 0); }
  100% {
    clip: rect(17px, 9999px, 79px, 0); } }

.about-us-wrapper {
  background-color: #333333; }
  .about-us-wrapper .section-content {
    height: 150px; }
  .about-us-wrapper .paragraph-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    color: #ffffff; }
    .about-us-wrapper .paragraph-container p {
      text-align: center;
      transition: .5s;
      color: #ffffff;
      opacity: 0;
      position: absolute;
      top: auto; }
      .about-us-wrapper .paragraph-container p.show {
        opacity: .9; }

.heart {
  fill: #ffb400;
  position: relative;
  top: 10px;
  width: 20px;
  animation: pulse 1s ease infinite; }

@keyframes pulse {
  0% {
    transform: scale(1); }
  10% {
    transform: scale(1.3); }
  20% {
    transform: scale(1); }
  30% {
    transform: scale(1.3); }
  100% {
    transform: scale(1); } }

.schedule-section {
  background-color: #ffffff; }
  .schedule-section .title-container {
    padding: 30px 30px; }
  .schedule-section .mobile-tabs {
    display: flex;
    justify-content: center; }
    @media only screen and (max-width: 979px) {
      .schedule-section .mobile-tabs {
        flex-wrap: wrap; } }
    .schedule-section .mobile-tabs .tab {
      display: flex;
      flex-direction: column;
      width: 16.66667%; }
      @media only screen and (max-width: 979px) {
        .schedule-section .mobile-tabs .tab {
          width: 33.33333%; } }
      @media only screen and (max-width: 599px) {
        .schedule-section .mobile-tabs .tab {
          width: 100%; } }
    .schedule-section .mobile-tabs .tab-content,
    .schedule-section .mobile-tabs .tab-trigger {
      text-align: center; }
      .schedule-section .mobile-tabs .tab-content p,
      .schedule-section .mobile-tabs .tab-trigger p {
        text-transform: capitalize; }
    .schedule-section .mobile-tabs .tab-trigger {
      background-color: #333333;
      color: #ffffff;
      border: 1px solid #ffffff; }
      .schedule-section .mobile-tabs .tab-trigger p {
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 10px; }
    .schedule-section .mobile-tabs .tab-content {
      display: flex;
      flex-direction: column;
      flex: 1; }
      .schedule-section .mobile-tabs .tab-content p {
        margin-top: 10px; }
        .schedule-section .mobile-tabs .tab-content p + p {
          margin-bottom: 10px; }
      .schedule-section .mobile-tabs .tab-content .training-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        border: 1px solid #ffffff; }
        .schedule-section .mobile-tabs .tab-content .training-info-container[data-training='kalistenika Z'] {
          flex: 2;
          padding-top: 1px;
          padding-bottom: 1px; }
  .schedule-section [data-training*='reborn'] {
    background-color: #890000;
    color: #ffffff; }
  .schedule-section [data-training*='kalistenika'] {
    background-color: #ffb400;
    color: #333333; }
  .schedule-section [data-training*='obwodowy'] {
    background-color: #00a651;
    color: #ffffff; }
  .schedule-section [data-training*='rozciąganie'] {
    background-color: #4f5bff;
    color: #ffffff; }
  .schedule-section [data-training*='wolna sala'] {
    background-color: #797979;
    color: #ffffff; }

.pricing-wrapper {
  background-color: #333333;
  padding-bottom: 30px; }
  .pricing-wrapper .pricing-header .pricing-notice {
    display: block;
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: bold; }
  .pricing-wrapper .pricing-details {
    display: flex; }
    @media only screen and (max-width: 979px) {
      .pricing-wrapper .pricing-details {
        flex-wrap: wrap; } }
  .pricing-wrapper h3,
  .pricing-wrapper .pricing {
    color: #ffffff; }
  .pricing-wrapper .pricing {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #666666;
    border-top: 0; }
    .pricing-wrapper .pricing + .pricing {
      border-left: 0; }
      @media only screen and (max-width: 599px) {
        .pricing-wrapper .pricing + .pricing {
          border-left: 1px solid #666666; }
          .pricing-wrapper .pricing + .pricing h3 {
            border-top: 0; } }
    @media only screen and (max-width: 979px) {
      .pricing-wrapper .pricing {
        width: 50%; }
        .pricing-wrapper .pricing:nth-child(4n) h3 {
          border-top: 0; }
        .pricing-wrapper .pricing:nth-child(4n-1) {
          border-left: 1px solid #666666; }
          .pricing-wrapper .pricing:nth-child(4n-1) h3 {
            border-top: 0; } }
    @media only screen and (max-width: 599px) {
      .pricing-wrapper .pricing {
        width: 100%; } }
    .pricing-wrapper .pricing h3 {
      border-top: 1px solid #666666;
      border-bottom: 1px solid #666666;
      padding: 10px;
      font-size: 25px;
      background-color: #454545; }
    .pricing-wrapper .pricing p {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px; }
      .pricing-wrapper .pricing p.first:not(:last-of-type) {
        border-bottom: 1px solid #666666; }
      .pricing-wrapper .pricing p:not(.first) {
        height: 100%; }
  .pricing-wrapper .price-wrapper {
    flex: 1; }
    .pricing-wrapper .price-wrapper .price-value {
      color: #ffb400;
      font-weight: bold; }

.trainers-wrapper {
  background-color: #ffffff; }
  .trainers-wrapper .pictures-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .trainers-wrapper .picture-container {
    max-width: 320px;
    position: relative;
    padding: 10px; }
    .trainers-wrapper .picture-container:hover img {
      opacity: 0; }
    .trainers-wrapper .picture-container:hover .paragraph-wrapper {
      opacity: 1; }
    .trainers-wrapper .picture-container img {
      z-index: 1;
      transition: .2s; }
    .trainers-wrapper .picture-container .paragraph-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 30px;
      text-align: center;
      opacity: 0; }
      .trainers-wrapper .picture-container .paragraph-wrapper p {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        letter-spacing: 1.4px; }
        .trainers-wrapper .picture-container .paragraph-wrapper p a {
          font-weight: bold; }

.contact-wrapper {
  background-color: #ffffff; }
  .contact-wrapper .contact-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .contact-wrapper .contact-details p + p {
      padding-top: 10px; }
    .contact-wrapper .contact-details .media-links i {
      font-size: 24px;
      color: #333333; }
      .contact-wrapper .contact-details .media-links i:hover {
        color: #ffb400; }

#map {
  width: 100%;
  height: 600px;
  box-shadow: 1px 7px 10px -9px rgba(0, 0, 0, 0.75); }

.history-wrapper {
  background-color: #ffffff; }

.trainings-wrapper {
  text-align: center; }
  .trainings-wrapper a {
    font-weight: bold; }
  .trainings-wrapper p {
    line-height: 140%; }
    .trainings-wrapper p + p {
      margin-top: 20px; }
  .trainings-wrapper .subtitle {
    font-family: "PT Sans Narrow", sans-serif;
    text-align: center;
    font-weight: bold;
    font-size: 22px; }

footer {
  background-color: #333333; }
  footer.section-wrapper {
    border-top: 0; }
  footer .copyright {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media only screen and (max-width: 599px) {
      footer .copyright {
        justify-content: center;
        flex-direction: column; } }
    footer .copyright .copy-icon {
      margin-right: 5px; }
    footer .copyright a {
      display: block;
      font-size: 15px;
      color: #ffffff; }
      footer .copyright a.home {
        position: relative; }
        footer .copyright a.home:before {
          position: absolute;
          top: -2px;
          left: -7px;
          content: '';
          display: block;
          height: 20px;
          width: 1px;
          background-color: #ffffff;
          opacity: .4; }
      footer .copyright a:hover {
        color: #ffb400; }
      footer .copyright a.facebook {
        font-size: 20px; }
      footer .copyright a + a {
        margin-left: 15px; }
    footer .copyright .footer-links {
      display: flex; }
    footer .copyright .media-links {
      font-size: 20px; }
      @media only screen and (max-width: 599px) {
        footer .copyright .media-links {
          padding-top: 20px; } }

@media (max-width: 770px) {
  .mobile-heading {
    background: #ffffff; }
    .mobile-heading .logo {
      width: 50%;
      height: auto;
      padding: 15px 0; } }
