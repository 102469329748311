.history-wrapper {
  background-color: $c-white; }

.trainings-wrapper {
  text-align: center;

  a {
    font-weight: bold; }

  p {
    line-height: 140%;

    + p {
      margin-top: 20px; } }

  .subtitle {
    font-family: $f-pt-sans-narrow;
    text-align: center;
    font-weight: bold;
    font-size: 22px; } }
